
<template>
	<div class="project-overview-grid grid grid--xs flex flex--wrap mb-32">
		<div class="project-overview-grid__column grid__item a4-12 d6-6">
			<div class="project-overview-grid__image-card-container flex flex--column flex--gap-medium">
				<button
					class="project-highlight-card project-highlight-card--image"
					@click="emit('set-route', 'grid' )"
				>
					<img
						v-if="latest_option && ( latest_option.image_large_url || latest_option.thumbnail_url )"
						:src="latest_option.image_large_url || latest_option.thumbnail_url"
						:alt="latest_option.title"
						class="project-highlight-card__image"
					>

					<div
						v-else
						class="project-highlight-card__image project-highlight-card__image--default"
					/>

					<div class="project-highlight-card__text">
						<p class="project-highlight-card__title">
							Latest images
						</p>
						<p class="project-highlight-card__action">
							View grid
						</p>
					</div>
				</button>
				<button
					class="project-highlight-card project-highlight-card--image"
					@click="emit('set-route', 'submissions' )"
				>
					<img
						v-if="latest_submission_option"
						:src="latest_submission_option.image_large_url || latest_submission_option.thumbnail_url"
						:alt="latest_submission_option.title"
						class="project-highlight-card__image"
					>

					<div
						v-else
						class="project-highlight-card__image project-highlight-card__image--default"
					/>

					<div class="project-highlight-card__text">
						<p class="project-highlight-card__title">
							Latest submissions
						</p>
						<p class="project-highlight-card__action">
							View submissions
						</p>
					</div>
				</button>
			</div>
		</div>
		<div class="project-overview-grid__column grid__item a4-12 d6-6">
			<div class="project-highlight-card">
				<div class="project-highlight-card__text">
					<p class="project-highlight-card__title">
						Timetable
					</p>
					<dl class="project-details">
						<template
							v-for="item, index in project_timetable"
							:key="`project-timetable-${ index }`"
						>
							<div
								v-if="item.title"
								class="project-details__item"
							>
								<dt class="project-details__item-header">
									{{ item.label }}
								</dt>
								<dd class="project-details__item-content">
									<button
										v-if="user_is_staff_admin && view_mode === 'staff' && item.label === 'Project Status'"
										class="project-details__link"
										@click="show_project_status_modal = true"
									>
										{{  item.title }}
									</button>
									<p v-else>
										{{ item.title }}
									</p>
								</dd>
							</div>
						</template>
						<div
							v-for="item, index in project_documentation"
							:key="`project-documentation-${ index }`"
							class="project-details__item"
						>
							<dt class="project-details__item-header">
								{{ item.label }}
							</dt>
							<dd class="project-details__item-content">
								<p>
									{{ item.title }}
								</p>
								<a
									v-if="item.external_link"
									:href="item.external_link"
									class="project-details__external-link"
									target="_blank"
									rel="noopener"
									aria-label="Open external link"
								/>
							</dd>
						</div>
					</dl>
				</div>

				<div
					v-if="project_team"
					class="project-highlight-card__text"
				>
					<p class="project-highlight-card__title">
						Team
					</p>
					<dl class="project-details project-details--team">
						<template
							v-for="user, index in project_team"
							:key="`project-team-${ index }`"
						>
							<div
								:class="{'mt-16': user.role === 'Key Client Contact' }"
								class="project-details__item project-details__item--image"
							>
								<div class="project-details__item-image">
									<ProfilePictureComponent
										:user="user"
										size="md"
									/>
								</div>
								<div>
									<dt class="project-details__item-header">
										{{ user.role }}
									</dt>
									<dd class="project-details__item-content">
										<p>
											{{ user.fullName }}
										</p>
									</dd>
								</div>
							</div>
						</template>
					</dl>
				</div>
			</div>
		</div>
		<div class="project-overview-grid__column grid__item a4-12 d6-6">
			<div class="project-highlight-card">
				<div class="project-highlight-card__text">
					<p class="project-highlight-card__title">
						Project information
					</p>
					<dl class="project-details">
						<p v-if="!project_info.find( item => item.values.length > 0 )">
							No information available
						</p>
						<template
							v-for="item, index in project_info"
							:key="`project-info-${ index }`"
						>
							<div
								v-if="item.values.length"
								class="project-details__item"
							>
								<dt class="project-details__item-header">
									{{ item.label }}
								</dt>
								<dd
									v-for="value in item.values"
									:key="item.label + '-' + value.id"
									class="project-details__item-content"
								>
									<template v-if="item.label ==='Location'">
										<router-link
											v-if="user_is_staff && view_mode === 'staff' && value.filter_route"
											class="project-details__link"
											:to="value.filter_route"
										>
											{{  value.title }}
										</router-link>
										<p v-else>
											{{ value.title }}
										</p>
										<a
											v-if="value.external_link"
											:href="value.external_link"
											class="project-details__link"
											target="_blank"
											rel="noopener"
											aria-label="Open location in Google Maps"
										>
											Maps
										</a>
									</template>
									<template v-else>
										<router-link
											v-if="user_is_staff && view_mode === 'staff' && value.filter_route"
											class="project-details__link"
											:to="value.filter_route"
										>
											{{  value.title }}
										</router-link>
										<p v-else>
											{{ value.title }}
										</p>
										<a
											v-if="value.external_link"
											:href="value.external_link"
											class="project-details__external-link"
											target="_blank"
											rel="noopener"
											aria-label="Open external link"
										/>
									</template>
								</dd>
							</div>
						</template>
					</dl>
				</div>
			</div>
		</div>
	</div>

	<Teleport to="#modal">
		<Transition name="fade">
			<ProjectStatusModalComponent
				v-if="user_is_staff_admin && show_project_status_modal"
				:project="project"
				@status-updated="$emit('refresh-project')"
				@close-modal="show_project_status_modal = false"
			/>
		</Transition>
	</Teleport>
</template>

<script setup>
import ProfilePictureComponent from './ProfilePictureComponent.vue';
import ProjectStatusModalComponent from './modals/ProjectStatusModalComponent.vue';

import { ref, computed, onMounted, defineProps, watch } from 'vue';
import { useDataStore } from '../stores/data';
import { getProjectThumbnailOption } from '../../helpers';
import { USER_GROUP_STAFF, USER_GROUP_CLIENT } from '../constants';

import moment from 'moment';

const emit = defineEmits( ['set-route', 'refresh-project'] );

const { isUserStaffSuperAdmin, user_group } = useDataStore();

const props = defineProps( {
 	project: {
		type: Object,
		required: true
	},
	submissions: {
		type: Array,
		required: true
	},
	viewMode: {
		type: String,
		required: false,
		default: 'staff'
	}
} );

const project = computed( () => {
	return props.project;
} );
const submissions = computed( () => {
	return props.submissions;
} );
const view_mode = computed( () => {
	return props.viewMode;
} );

const user_is_staff_admin = ref( false );
const show_project_status_modal = ref( false );

// General
const user_is_staff = computed( () => {
	return user_group === USER_GROUP_STAFF;
});

const user_is_client = computed( () => {
	return user_group === USER_GROUP_CLIENT;
});

const latest_option = computed( () => {
	if ( !project ) {
		return null;
	}
	return getLatestOption() || null;
} );

const latest_submission_option = computed( () => {
	if ( !submissions.value || !submissions.value.length ) {
		return null;
	}
	return getLatestSubmissionOption() || null;
} );

const project_info = computed( () => {
	if ( !project.value ) {
		return [];
	}
	return [
		{
			label: 'Client',
			values: project.value.client.length ? project.value.client.map( company => formatProjectInfoCompany( company, 'client' ) ) : []
		},
		{
			label: 'Location',
			values: project.value.project_location.length ? [
				{
					...project.value.project_location.at( -1 ),
					filter_route: {
						name: 'projects', query: {
							location: project.value.project_location.at( -1 ).id
						}
					},
					external_link: project.value.project_coordinates ? formatGoogleMapsUrl( project.value.project_coordinates ) : null
				}
			] : []
		},
		{
			label: 'Marketing Name',
			values: project.value.marketing_name && project.value.marketing_name.length ? [
				{
					title: project.value.marketing_name,
					external_link: project.value.marketing_website ?? null
				}
			] : []
		},
		{
			label: 'Creative Agency',
			values: project.value.creative_agency.length ? project.value.creative_agency.map( company => formatProjectInfoCompany( company, 'creative_agency' ) ) : []
		},
		{
			label: 'Sales & Marketing',
			values: project.value.sales_marketing.length ? project.value.sales_marketing.map( company => formatProjectInfoCompany( company, 'sales_marketing' ) ) : []
		},
		{
			label: 'Hospitality Operator',
			values: project.value.hospitality_operator.length ? project.value.hospitality_operator.map( company => formatProjectInfoCompany( company, 'hospitality_operator' ) ) : []
		},
		{
			label: 'Brand Partner',
			values: project.value.brand_partner.length ? project.value.brand_partner.map( company => formatProjectInfoCompany( company, 'brand_partner' ) ) : []
		},
		{
			label: 'Architect',
			values: project.value.architect.length ? project.value.architect.map( company => formatProjectInfoCompany( company, 'architect' ) ) : []
		},
		{
			label: 'Landscape Architect',
			values: project.value.landscape_architect.length ? project.value.landscape_architect.map( company => formatProjectInfoCompany( company, 'landscape_architect' ) ) : []
		},
		{
			label: 'Interior Design',
			values: project.value.interior_design.length ? project.value.interior_design.map( company => formatProjectInfoCompany( company, 'interior_design' ) ) : []
		},
		{
			label: 'Developer',
			values: project.value.developer.length ? project.value.developer.map( company => formatProjectInfoCompany( company, 'developer' ) ) : []
		},
		{
			label: 'Development Consultant',
			values: project.value.development_consultant.length ? project.value.development_consultant.map( company => formatProjectInfoCompany( company, 'development_consultant' ) ) : []
		},
	];
} );

const project_timetable = computed( () => {
	if ( !project.value ) {
		return null;
	}
	return [
		{
			label: 'Kick-off',
			title: project.value.kick_off ? formatProjectDate( project.value.kick_off ) : null
		},
		{
			label: 'Target completion',
			title: project.value.completion_date ? formatProjectDate( project.value.completion_date ) : null
		},
		{
			label: 'Project Status',
			title: capitaliseFirstLetter( project.value.project_status ) || null
		}
	];
} );

const project_documentation = computed( () => {
	if ( !project.value ) {
		return null;
	}
	return project.value.project_documentation
		.filter( item => {
			if ( view_mode.value === 'client' || user_is_client.value ) {
				return item.visible_to_client;
			}
			return true;
		} )
		.map( item => {
		return {
			label: item.item_label,
			title: item.item_title,
			external_link: item.external_url
		}
	} )
} );

const project_team = computed( () => {
	if ( !project.value ) {
		return null;
	}
	const team = [
		...project.value.business_development.map( user => { return { ...user, role: 'Business Development Director' } } ),
		...project.value.account_director.map( user => { return { ...user, role: 'Account Director' } } ),
		...project.value.project_manager.map( user => { return { ...user, role: 'Project Manager' } } ),
		...project.value.lead_artist.map( user => { return { ...user, role: 'Lead Artist' } } )
	];
	if ( user_is_staff.value && view_mode.value === 'staff' ) {
		team.push( ...project.value.key_client_contact.map( user => { return { ...user, role: 'Key Client Contact' } } ) )
	}

	return team;
} );

onMounted( async () => {
	user_is_staff_admin.value = await isUserStaffSuperAdmin();
} );

function getLatestOption() {
	return getProjectThumbnailOption( project.value );
}

function getLatestSubmissionOption() {
	const latest_submission = submissions.value
		.filter( submission => submission.options.length )
		.at( 0 )
	;
	if ( !latest_submission ) {
		return null;
	}

	const sorted_options = latest_submission.options
		.slice()
		.sort( ( a, b ) => a.title > b.title ? 1 : -1 );
	const latest_option = sorted_options.at( -1 );

	return latest_option || null;
}

function formatProjectInfoCompany( company, slug ) {
	const filter_route = { name: 'projects', query: {} };
	filter_route.query[slug] = company.id;

	return {
		...company,
		filter_route,
		external_link: company.company_url ?? null
	}
}

function formatProjectDate( date ) {
	return moment( new Date( date ) ).format( 'D MMMM YYYY' );
}

function formatGoogleMapsUrl( coordinates ) {
	return `https://maps.google.com/?q=${coordinates}`;
}

function capitaliseFirstLetter( string ) {
	if ( !string.length ) {
		return string;
	}
	return string[0].toUpperCase() + string.slice(1);
}
</script>
